import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Heading, Button, Spacer } from "@chakra-ui/react";
import { logout } from '../store/authSlice';

const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box bg="green.900" py={2} px={4}>
      <Flex maxW="container.xl" mx="auto" align="center">
        <Heading as="h1" size="lg" color="white" fontWeight="bold">
          GenAI Platform
        </Heading>
        <Spacer />
        {user ? (
          <>
            <Button
              as={RouterLink}
              to="/dashboard"
              variant="ghost"
              color="white"
              mr={4}
              _hover={{ bg: 'green.700', color: 'white' }}
            >
              Dashboard
            </Button>
            <Button
              onClick={handleLogout}
              variant="ghost"
              color="white"
              _hover={{ bg: 'green.700', color: 'white' }}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              as={RouterLink}
              to="/login"
              variant="ghost"
              color="white"
              mr={4}
              _hover={{ bg: 'green.700', color: 'white' }}
            >
              Login
            </Button>
            <Button
              as={RouterLink}
              to="/register"
              variant="ghost"
              color="white"
              _hover={{ bg: 'green.700', color: 'white' }}
            >
              Register
            </Button>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Header;
