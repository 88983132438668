import React, { useState, useEffect } from 'react';
import { Text } from "@chakra-ui/react";

const TypewriterEffect = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayText(prevText => prevText + text[index]);
        setIndex(prevIndex => prevIndex + 1);
      }, 10); // Adjust typing speed here

      return () => clearTimeout(timer);
    }
  }, [index, text]);

  return <Text>{displayText}</Text>;
};

export default TypewriterEffect;