import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import useCaseReducer from './useCaseSlice';
import chatReducer from './chatSlice';
import analyticsReducer from './analyticsSlice';
import queryReducer from './querySlice';
import contentModerationReducer from './contentModerationSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    useCase: useCaseReducer,
    chat: chatReducer,
    analytics: analyticsReducer,
    query: queryReducer,
    contentModeration: contentModerationReducer,
  },
});

export default store;