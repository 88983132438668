import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

export const fetchContentModerationSettings = createAsyncThunk(
  'contentModeration/fetchSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/content-moderation/settings');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch content moderation settings');
    }
  }
);

export const updateContentModerationSettings = createAsyncThunk(
  'contentModeration/updateSettings',
  async (settings, { rejectWithValue }) => {
    try {
      const response = await api.put('/content-moderation/settings', settings);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update content moderation settings');
    }
  }
);

const contentModerationSlice = createSlice({
  name: 'contentModeration',
  initialState: {
    settings: {
      enableContentFiltering: true,
      profanityFilterStrength: 50,
      blockExplicitContent: true,
      enablePersonalInfoRedaction: true,
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentModerationSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContentModerationSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(fetchContentModerationSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateContentModerationSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateContentModerationSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload;
      })
      .addCase(updateContentModerationSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default contentModerationSlice.reducer;