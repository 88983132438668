import React, { useEffect, useState, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Flex, VStack, HStack, Heading, Text, Button, Select, Input,
  useColorMode, useColorModeValue, SimpleGrid, Container, useToast,
  Stat, StatLabel, StatNumber, StatHelpText, StatArrow, Progress, useDisclosure,
  IconButton, Alert, AlertIcon, AlertTitle, AlertDescription, Spinner,
  Menu, MenuButton, MenuList, MenuItem,
  Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
  Switch, FormControl, FormLabel, Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import { AddIcon, SearchIcon, SunIcon, MoonIcon, ChevronDownIcon, UploadIcon } from '@chakra-ui/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import debounce from 'lodash/debounce';
import {
  fetchUseCases,
  fetchCategories,
  fetchTrendingUseCases,
  deleteUseCase,
  fetchUseCaseStats,
  bulkDeleteUseCases,
  bulkUpdateUseCases
} from '../store/useCaseSlice';
import UseCaseCard from './UseCaseCard';
import UseCaseModal from './UseCaseModal';
import FileUploadModal from './FileUploadModal';
import Pagination from './Pagination';
import SkeletonLoader from './SkeletonLoader';
import { executeUseCase } from '../services/useCaseService';
import api from '../utils/api';
import AdvancedAnalyticsDashboard from './AdvancedAnalyticsDashboard';
import NaturalLanguageQueryInterface from './NaturalLanguageQueryInterface';
import ContentModerationSettings from './ContentModerationSettings';

const ITEMS_PER_PAGE = 9;

const Dashboard = () => {
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isUploadModalOpen, onOpen: onUploadModalOpen, onClose: onUploadModalClose } = useDisclosure();
  const toast = useToast();

  const {
    useCases,
    categories,
    trendingUseCases,
    isLoading,
    error,
    totalUseCases,
    useCaseStats
  } = useSelector((state) => state.useCase);

  const [executingUseCase, setExecutingUseCase] = useState(null);
  const [executionResults, setExecutionResults] = useState({});
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [selectedUseCaseForUpload, setSelectedUseCaseForUpload] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedUseCases, setSelectedUseCases] = useState([]);
  const [showUsageTrends, setShowUsageTrends] = useState(false);
  const [showTrendingUseCases, setShowTrendingUseCases] = useState(false);

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const chartLineColor = useColorModeValue("blue.500", "blue.200");

  const fetchUseCasesData = useCallback(() => {
    dispatch(fetchUseCases({
      category: selectedCategory,
      search: searchQuery,
      page: currentPage,
      limit: ITEMS_PER_PAGE,
      sortBy,
      sortOrder
    }));
  }, [dispatch, selectedCategory, searchQuery, currentPage, sortBy, sortOrder]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchTrendingUseCases());
    dispatch(fetchUseCaseStats());
    fetchUseCasesData();
  }, [dispatch, fetchUseCasesData]);

  const handleCreateNew = () => {
    setSelectedUseCase(null);
    onOpen();
  };

  const handleEdit = (useCase) => {
    setSelectedUseCase(useCase);
    onOpen();
  };

  const handleDelete = useCallback((useCaseId) => {
    if (window.confirm('Are you sure you want to delete this use case?')) {
      dispatch(deleteUseCase(useCaseId)).then(() => {
        fetchUseCasesData();
      });
    }
  }, [dispatch, fetchUseCasesData]);

  const handleBulkDelete = () => {
    if (window.confirm(`Are you sure you want to delete ${selectedUseCases.length} use cases?`)) {
      dispatch(bulkDeleteUseCases(selectedUseCases)).then(() => {
        setSelectedUseCases([]);
        fetchUseCasesData();
      });
    }
  };


  const handleBulkUpdateStatus = (status) => {
    dispatch(bulkUpdateUseCases({ ids: selectedUseCases, updates: { status } })).then(() => {
      setSelectedUseCases([]);
      fetchUseCasesData();
    });
  };

  const handleExecuteUseCase = async (useCaseId, input, userContext) => {
    setExecutingUseCase(useCaseId);
    try {
      const result = await executeUseCase(useCaseId, input, userContext);
      setExecutionResults(prev => ({
        ...prev,
        [useCaseId]: { result, error: null }
      }));
    } catch (error) {
      console.error('Failed to execute use case:', error);
      setExecutionResults(prev => ({
        ...prev,
        [useCaseId]: { result: null, error: error.message || 'An error occurred during execution' }
      }));
      toast({
        title: "Execution Failed",
        description: error.message || "An error occurred during execution",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setExecutingUseCase(null);
    }
  };

  const handleOpenUploadModal = (useCase) => {
    setSelectedUseCaseForUpload(useCase);
    onUploadModalOpen();
  };

  const renderSectionToggles = () => (
    <HStack spacing={4} mb={4}>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="usage-trends-toggle" mb="0">
          Show Usage Trends
        </FormLabel>
        <Switch
          id="usage-trends-toggle"
          isChecked={showUsageTrends}
          onChange={(e) => setShowUsageTrends(e.target.checked)}
        />
      </FormControl>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="trending-usecases-toggle" mb="0">
          Show Trending Use Cases
        </FormLabel>
        <Switch
          id="trending-usecases-toggle"
          isChecked={showTrendingUseCases}
          onChange={(e) => setShowTrendingUseCases(e.target.checked)}
        />
      </FormControl>
    </HStack>
  );

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = debounce((e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  }, 300);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortChange = (newSortBy) => {
    if (newSortBy === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortOrder('asc');
    }
  };

  const handleUseCaseSelection = (useCaseId) => {
    setSelectedUseCases(prev =>
      prev.includes(useCaseId)
        ? prev.filter(id => id !== useCaseId)
        : [...prev, useCaseId]
    );
  };

  const renderErrorMessage = (error) => {
    if (typeof error === 'string') {
      return error;
    } else if (error && error.message) {
      return error.message;
    } else {
      return 'An unknown error occurred';
    }
  };

  const renderStats = () => {
    const statusCounts = useCases.reduce((acc, useCase) => {
      acc[useCase.status] = (acc[useCase.status] || 0) + 1;
      return acc;
    }, {});
    const totalCount = useCases.length;

    return (
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
        <Stat bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor}>
          <StatLabel color={textColor}>Total Use Cases</StatLabel>
          <StatNumber color={textColor}>{totalUseCases || 0}</StatNumber>
          <StatHelpText color={secondaryTextColor}>
            <StatArrow type="increase" />
            {totalUseCases > 0 ? Math.round((useCases.length / totalUseCases) * 100) : 0}% active
          </StatHelpText>
        </Stat>
        <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor}>
          <Text fontWeight="bold" mb={2} color={textColor}>Categories</Text>
          <Select value={selectedCategory} onChange={handleCategoryChange} bg={cardBgColor} color={textColor}>
            <option value="">All Categories</option>
            {categories && categories.map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </Select>
        </Box>
        <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor}>
          <Text fontWeight="bold" mb={2} color={textColor}>Use Case Status</Text>
          <VStack align="stretch" spacing={2}>
            <HStack justify="space-between">
              <Text color={textColor}>Published:</Text>
              <Text color={textColor}>{statusCounts.published || 0}</Text>
            </HStack>
            <Progress value={(statusCounts.published || 0) / totalCount * 100} size="sm" colorScheme="green" />
            <HStack justify="space-between">
              <Text color={textColor}>Draft:</Text>
              <Text color={textColor}>{statusCounts.draft || 0}</Text>
            </HStack>
            <Progress value={(statusCounts.draft || 0) / totalCount * 100} size="sm" colorScheme="yellow" />
            <HStack justify="space-between">
              <Text color={textColor}>Archived:</Text>
              <Text color={textColor}>{statusCounts.archived || 0}</Text>
            </HStack>
            <Progress value={(statusCounts.archived || 0) / totalCount * 100} size="sm" colorScheme="red" />
          </VStack>
        </Box>
      </SimpleGrid>
    );
  };

  const renderUsageTrends = () => (
    <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor} mt={6}>
      <Heading size="md" mb={4} color={textColor}>Usage Trends</Heading>
      {useCaseStats && useCaseStats.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={useCaseStats}>
            <CartesianGrid strokeDasharray="3 3" stroke={borderColor} />
            <XAxis dataKey="date" stroke={textColor} />
            <YAxis stroke={textColor} />
            <Tooltip 
              contentStyle={{ 
                backgroundColor: cardBgColor, 
                color: textColor, 
                borderColor: borderColor 
              }} 
            />
            <Line type="monotone" dataKey="usageCount" stroke={chartLineColor} />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Text color={secondaryTextColor}>Loading usage data...</Text>
      )}
    </Box>
  );

  const renderTrendingUseCases = () => (
    <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor} mt={6}>
      <Heading size="md" mb={4} color={textColor}>Trending Use Cases</Heading>
      {trendingUseCases && trendingUseCases.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          {trendingUseCases.map((useCase) => (
            <UseCaseCard
              key={useCase.uniqueIdentifier}
              useCase={useCase}
              onEdit={() => handleEdit(useCase)}
              onDelete={() => handleDelete(useCase.uniqueIdentifier)}
              isSelected={selectedUseCases.includes(useCase.uniqueIdentifier)}
              onSelect={() => handleUseCaseSelection(useCase.uniqueIdentifier)}
              bgColor={cardBgColor}
              textColor={textColor}
              borderColor={borderColor}
            />
          ))}
        </SimpleGrid>
      ) : (
        <Text color={secondaryTextColor}>No trending use cases available</Text>
      )}
    </Box>
  );
  
  const ErrorFallback = ({error}) => {
  return (
    <Box role="alert">
      <Text>Something went wrong:</Text>
      <Text color="red.500">{error.message}</Text>
    </Box>
  );
};

  const renderUseCases = () => (
    <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm" border="1px" borderColor={borderColor} mt={6}>
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="md" color={textColor}>Your Use Cases</Heading>
        <HStack>
          <Menu>
            <MenuButton as={Button} colorScheme="blue" rightIcon={<ChevronDownIcon />}>
              Sort By
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleSortChange('createdAt')}>Created Date</MenuItem>
              <MenuItem onClick={() => handleSortChange('name')}>Name</MenuItem>
              <MenuItem onClick={() => handleSortChange('category')}>Category</MenuItem>
            </MenuList>
          </Menu>
          {selectedUseCases.length > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Bulk Actions
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleBulkDelete}>Delete Selected</MenuItem>
                <MenuItem onClick={() => handleBulkUpdateStatus('published')}>Mark as Published</MenuItem>
                <MenuItem onClick={() => handleBulkUpdateStatus('archived')}>Archive Selected</MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Flex>
      {isLoading ? (
        <SimpleGrid columns={{ base: 1, md: 3}} spacing={4}>
{[...Array(ITEMS_PER_PAGE)].map((_, index) => (
<SkeletonLoader key={index} />
))}
</SimpleGrid>
) : useCases && useCases.length > 0 ? (
<>
<SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
{useCases.map((useCase) => (
<UseCaseCard
key={useCase.uniqueIdentifier}
useCase={useCase}
onEdit={() => handleEdit(useCase)}
onDelete={() => handleDelete(useCase.uniqueIdentifier)}
onExecute={handleExecuteUseCase}
isExecuting={executingUseCase === useCase.uniqueIdentifier}
executionResult={executionResults[useCase.uniqueIdentifier]?.result}
executionError={executionResults[useCase.uniqueIdentifier]?.error}
isSelected={selectedUseCases.includes(useCase.uniqueIdentifier)}
onSelect={() => handleUseCaseSelection(useCase.uniqueIdentifier)}
onUpload={() => handleOpenUploadModal(useCase)}
bgColor={cardBgColor}
textColor={textColor}
borderColor={borderColor}
/>
))}
</SimpleGrid>
<Pagination
         currentPage={currentPage}
         totalItems={totalUseCases}
         itemsPerPage={ITEMS_PER_PAGE}
         onPageChange={handlePageChange}
       />
</>
) : (
<Text color={secondaryTextColor}>You haven't created any use cases yet</Text>
)}
</Box>
);
return (
<Container maxW="container.xl" py={8}>
<Flex justify="space-between" align="center" mb={6}>
<Heading size="xl" color={textColor}>Dashboard</Heading>
<HStack>
<IconButton
icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
onClick={toggleColorMode}
aria-label="Toggle color mode"
size="lg"
color={textColor}
/>
<Button leftIcon={<AddIcon />} colorScheme="blue" onClick={handleCreateNew} size="lg">
Create New Use Case
</Button>
</HStack>
</Flex>
{isLoading && (
<Progress size="xs" isIndeterminate colorScheme="blue" />
)}
<Tabs>
  <TabList>
    <Tab>Use Cases</Tab>
    <Tab>Advanced Analytics</Tab>
    <Tab>Natural Language Query</Tab>
    <Tab>Content Moderation</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
      <HStack spacing={4} mb={6}>
        <Input
          placeholder="Search use cases..."
          onChange={handleSearchChange}
          bg={cardBgColor}
          color={textColor}
        />
        <IconButton
          aria-label="Search"
          colorScheme="blue"
          icon={<SearchIcon />}
          onClick={() => fetchUseCasesData()}
        />
      </HStack>
      {renderSectionToggles()}
      {error ? (
        <Alert status="error" mt={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{renderErrorMessage(error)}</AlertDescription>
        </Alert>
      ) : (
        <>
          {renderStats()}
          {showUsageTrends && renderUsageTrends()}
          {showTrendingUseCases && renderTrendingUseCases()}
          {renderUseCases()}
        </>
      )}
    </TabPanel>
    <TabPanel>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AdvancedAnalyticsDashboard />
      </ErrorBoundary>
    </TabPanel>
    <TabPanel>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NaturalLanguageQueryInterface />
      </ErrorBoundary>
    </TabPanel>
    <TabPanel>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ContentModerationSettings />
      </ErrorBoundary>
    </TabPanel>
  </TabPanels>
</Tabs>
<UseCaseModal
isOpen={isOpen}
onClose={onClose}
useCase={selectedUseCase}
onSave={() => {
onClose();
fetchUseCasesData();
}}
/>
<FileUploadModal
     isOpen={isUploadModalOpen}
     onClose={onUploadModalClose}
     useCase={selectedUseCaseForUpload}
   />
</Container>
);
};
export default Dashboard;
