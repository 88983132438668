import React from 'react';
import { Box, Skeleton, VStack } from "@chakra-ui/react";

const SkeletonLoader = () => (
  <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
    <VStack spacing={4} align="stretch">
      <Skeleton height="20px" width="80%" />
      <Skeleton height="20px" width="60%" />
      <Skeleton height="100px" />
      <Skeleton height="20px" width="40%" />
    </VStack>
  </Box>
);

export default SkeletonLoader;
