// src/pages/FileUploadPage.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useToast
} from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, fetchUseCases } from '../store/useCaseSlice';

const FileUploadPage = () => {
  const { useCaseId } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const [file, setFile] = useState(null);
  const useCase = useSelector(state => 
    state.useCase.useCases.find(uc => uc.uniqueIdentifier === useCaseId)
  );

  useEffect(() => {
    if (!useCase) {
      dispatch(fetchUseCases({}));
    }
  }, [dispatch, useCase]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast({
        title: "Error",
        description: "Please select a file to upload",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);
      await dispatch(uploadFile({ useCaseId, formData })).unwrap();
      toast({
        title: "File Uploaded",
        description: "File has been successfully uploaded and processed",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFile(null);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!useCase) {
    return <Text>Loading...</Text>;
  }

  if (!useCase.useVectorDB) {
    return <Text>Vector DB is not enabled for this use case.</Text>;
  }

  return (
    <Box p={5}>
      <Heading mb={5}>File Upload for {useCase.name}</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>Select File</FormLabel>
          <Input type="file" onChange={handleFileChange} />
        </FormControl>
        <Button colorScheme="blue" onClick={handleSubmit}>
          Upload File
        </Button>
      </VStack>
    </Box>
  );
};

export default FileUploadPage;