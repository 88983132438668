import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

export const fetchUseCases = createAsyncThunk(
  'useCase/fetchUseCases',
  async ({ page, limit, search, category, sortBy, sortOrder }, { rejectWithValue }) => {
    try {
      const response = await api.get('/usecases', {
        params: { page, limit, search, category, sortBy, sortOrder }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch use cases');
    }
  }
);

export const fetchCategories = createAsyncThunk(
  'useCase/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/usecases/categories');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch categories');
    }
  }
);

export const fetchTrendingUseCases = createAsyncThunk(
  'useCase/fetchTrendingUseCases',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/usecases/trending');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch trending use cases');
    }
  }
);

export const fetchUseCaseStats = createAsyncThunk(
  'useCase/fetchUseCaseStats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/usecases/stats');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch use case stats');
    }
  }
);

export const createUseCase = createAsyncThunk(
  'useCase/createUseCase',
  async (useCaseData, { rejectWithValue }) => {
    try {
      const response = await api.post('/usecases', useCaseData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create use case');
    }
  }
);

export const updateUseCase = createAsyncThunk(
  'useCase/updateUseCase',
  async (useCaseData, { rejectWithValue }) => {
    try {
      const { uniqueIdentifier, ...updates } = useCaseData;
      const response = await api.put(`/usecases/${uniqueIdentifier}`, updates);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update use case');
    }
  }
);

export const deleteUseCase = createAsyncThunk(
  'useCase/deleteUseCase',
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/usecases/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete use case');
    }
  }
);

export const executeUseCase = createAsyncThunk(
  'useCase/executeUseCase',
  async ({ useCaseId, input, context }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/usecases/execute/${useCaseId}`, { input, context });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to execute use case');
    }
  }
);

export const testLLMConnection = createAsyncThunk(
  'useCase/testLLMConnection',
  async (connectionData, { rejectWithValue }) => {
    try {
      const response = await api.post('/usecases/test-llm-connection', connectionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to test LLM connection');
    }
  }
);

export const testVectorDBConnection = createAsyncThunk(
  'useCase/testVectorDBConnection',
  async (connectionData, { rejectWithValue }) => {
    try {
      const response = await api.post('/usecases/test-vectordb-connection', connectionData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to test Vector DB connection');
    }
  }
);

export const uploadFile = createAsyncThunk(
  'useCase/uploadFile',
  async ({ useCaseId, formData }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/usecases/upload/${useCaseId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to upload file');
    }
  }
);

export const bulkDeleteUseCases = createAsyncThunk(
  'useCase/bulkDelete',
  async (useCaseIds, { rejectWithValue }) => {
    try {
      const response = await api.post('/usecases/bulk-delete', { useCaseIds });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete use cases');
    }
  }
);

export const bulkUpdateUseCases = createAsyncThunk(
  'useCase/bulkUpdate',
  async ({ ids, updates }, { rejectWithValue }) => {
    try {
      const response = await api.post('/usecases/bulk-update', { ids, updates });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update use cases');
    }
  }
);

const useCaseSlice = createSlice({
  name: 'useCase',
  initialState: {
    useCases: [],
    categories: [],
    trendingUseCases: [],
    useCaseStats: [],
    executionResult: null,
    executionError: null,
    isLoading: false,
    error: null,
    totalUseCases: 0,
    currentPage: 1,
    totalPages: 1,
    llmConnectionStatus: null,
    vectorDBConnectionStatus: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUseCases.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUseCases.fulfilled, (state, action) => {
        state.isLoading = false;
        state.useCases = action.payload.useCases;
        state.totalUseCases = action.payload.totalUseCases;
        state.totalPages = action.payload.totalPages;
        state.error = null;
      })
      .addCase(fetchUseCases.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchTrendingUseCases.fulfilled, (state, action) => {
        state.trendingUseCases = action.payload;
      })
      .addCase(fetchUseCaseStats.fulfilled, (state, action) => {
        state.useCaseStats = action.payload;
      })
      .addCase(createUseCase.fulfilled, (state, action) => {
        state.useCases.push(action.payload);
        state.totalUseCases += 1;
      })
      .addCase(updateUseCase.fulfilled, (state, action) => {
        const index = state.useCases.findIndex(uc => uc.uniqueIdentifier === action.payload.uniqueIdentifier);
        if (index !== -1) {
          state.useCases[index] = action.payload;
        }
      })
      .addCase(deleteUseCase.fulfilled, (state, action) => {
        state.useCases = state.useCases.filter(uc => uc.uniqueIdentifier !== action.payload);
        state.totalUseCases -= 1;
      })
      .addCase(executeUseCase.pending, (state) => {
        state.executionResult = null;
        state.executionError = null;
      })
      .addCase(executeUseCase.fulfilled, (state, action) => {
        state.executionResult = action.payload;
      })
      .addCase(executeUseCase.rejected, (state, action) => {
        state.executionError = action.payload;
      })
      .addCase(testLLMConnection.pending, (state) => {
        state.llmConnectionStatus = 'testing';
      })
      .addCase(testLLMConnection.fulfilled, (state) => {
        state.llmConnectionStatus = 'success';
      })
      .addCase(testLLMConnection.rejected, (state) => {
        state.llmConnectionStatus = 'failed';
      })
      .addCase(testVectorDBConnection.pending, (state) => {
        state.vectorDBConnectionStatus = 'testing';
      })
      .addCase(testVectorDBConnection.fulfilled, (state) => {
        state.vectorDBConnectionStatus = 'success';
      })
      .addCase(testVectorDBConnection.rejected, (state) => {
        state.vectorDBConnectionStatus = 'failed';
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        // You might want to update the state here if needed
        // For example, you could add a 'lastFileUpload' field to the relevant use case
      })
      .addCase(bulkDeleteUseCases.fulfilled, (state, action) => {
        state.useCases = state.useCases.filter(useCase => !action.payload.deletedIds.includes(useCase.id));
        state.totalUseCases -= action.payload.deletedIds.length;
      })
      .addCase(bulkUpdateUseCases.fulfilled, (state, action) => {
        state.useCases = state.useCases.map(useCase => 
          action.payload.updatedUseCases.find(updated => updated.id === useCase.id) || useCase
        );
      });
  },
});

export const { clearError, setCurrentPage } = useCaseSlice.actions;

export default useCaseSlice.reducer;