import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from 'react-redux';
import { createUseCase, updateUseCase, testLLMConnection, testVectorDBConnection } from '../store/useCaseSlice';


const UseCaseModal = ({ isOpen, onClose, useCase, onSave }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    model: '',
    modelApiKey: '',
    embeddingApiKey: '',
    prompt_template: '',
    category: '',
    tags: '',
    status: 'draft',
    version: '1.0.0',
    icon: '',
    externalAccess: false,
    allowedDomains: '',
    allowFileUpload: false,
    useVectorDB: false,
    vectorDbConfig: {
      type: '',
      apiKey: '',
      environment: '',
      indexName: '',
      connectionString: '',
      tableName: '',
      host: '',
      port: '',
      username: '',
      password: '',
      database: '',
    },
    restrictToEnterpriseData: false
  });

  const [llmConnectionStatus, setLLMConnectionStatus] = useState(null);
  const [vectorDBConnectionStatus, setVectorDBConnectionStatus] = useState(null);
  const [useLLMEmbedding, setUseLLMEmbedding] = useState(true);

  useEffect(() => {
    if (useCase) {
      setFormData({
        ...useCase,
        tags: useCase.tags.join(', '),
        allowedDomains: useCase.allowedDomains.join(', '),
        embeddingApiKey: useCase.embeddingApiKey || '',
        vectorDbConfig: useCase.vectorDbConfig || {
          type: '',
          apiKey: '',
          environment: '',
          indexName: '',
          connectionString: '',
          tableName: '',
          host: '',
          port: '',
          username: '',
          password: '',
          database: '',
        },
        restrictToEnterpriseData: useCase.restrictToEnterpriseData || false,
        useVectorDB: useCase.useVectorDB || false
      });
    }
  }, [useCase]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('vectorDbConfig.')) {
      const vectorDbField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        vectorDbConfig: {
          ...prev.vectorDbConfig,
          [vectorDbField]: value
        }
      }));
    } else if (type === 'checkbox') {
      setFormData(prev => ({ ...prev, [name]: checked }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const actionData = {
        ...formData,
        uniqueIdentifier: useCase?.uniqueIdentifier,
        tags: formData.tags.split(',').map(tag => tag.trim()),
        allowedDomains: formData.allowedDomains.split(',').map(domain => domain.trim()),
        useLLMEmbedding: useLLMEmbedding
      };
      const action = useCase ? updateUseCase(actionData) : createUseCase(actionData);
      const resultAction = await dispatch(action);
      if (createUseCase.fulfilled.match(resultAction) || updateUseCase.fulfilled.match(resultAction)) {
        toast({
          title: useCase ? "Use Case Updated" : "Use Case Created",
          description: "Your use case has been successfully saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onSave();
        onClose();
      } else {
        throw new Error(resultAction.error.message);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An error occurred while saving the use case",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const testLLMConnectionHandler = useCallback(async () => {
    try {
      setLLMConnectionStatus('testing');
      await dispatch(testLLMConnection({
        model: formData.model,
        apiKey: formData.modelApiKey
      })).unwrap();
      setLLMConnectionStatus('success');
      toast({
        title: "LLM Connection Test",
        description: "Connection successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setLLMConnectionStatus('failed');
      toast({
        title: "LLM Connection Test Failed",
        description: error.message || "An error occurred while testing the connection",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [dispatch, formData.model, formData.modelApiKey, toast]);

  const testVectorDBConnectionHandler = useCallback(async () => {
    try {
      setVectorDBConnectionStatus('testing');
      await dispatch(testVectorDBConnection(formData.vectorDbConfig)).unwrap();
      setVectorDBConnectionStatus('success');
      toast({
        title: "Vector DB Connection Test",
        description: "Connection successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setVectorDBConnectionStatus('failed');
      toast({
        title: "Vector DB Connection Test Failed",
        description: error.message || "An error occurred while testing the connection",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [dispatch, formData.vectorDbConfig, toast]);

  const renderEmbeddingApiKeyField = () => {
    if (['GPT-3.5', 'GPT-4', 'Claude', 'Cohere', 'Gemini', 'PaLM'].includes(formData.model)) {
      return (
        <FormControl>
          <FormLabel>Embedding API Key</FormLabel>
          <Input
            name="embeddingApiKey"
            type="password"
            value={formData.embeddingApiKey}
            onChange={handleChange}
            placeholder="Enter API key for embeddings"
          />
        </FormControl>
      );
    }
    return null;
  };

  const renderVectorDBFields = () => {
    switch (formData.vectorDbConfig.type) {
      case 'Pinecone':
        return (
          <>
            <FormControl>
              <FormLabel>API Key</FormLabel>
              <Input
                name="vectorDbConfig.apiKey"
                value={formData.vectorDbConfig.apiKey}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Environment</FormLabel>
              <Input
                name="vectorDbConfig.environment"
                value={formData.vectorDbConfig.environment}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Index Name</FormLabel>
              <Input
                name="vectorDbConfig.indexName"
                value={formData.vectorDbConfig.indexName}
                onChange={handleChange}
              />
            </FormControl>
          </>
        );
      case 'Milvus':
        return (
          <>
            <FormControl>
              <FormLabel>Host</FormLabel>
              <Input
                name="vectorDbConfig.host"
                value={formData.vectorDbConfig.host}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Port</FormLabel>
              <Input
                name="vectorDbConfig.port"
                value={formData.vectorDbConfig.port}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Collection Name</FormLabel>
              <Input
                name="vectorDbConfig.indexName"
                value={formData.vectorDbConfig.indexName}
                onChange={handleChange}
              />
            </FormControl>
          </>
        );
      case 'FAISS':
        return (
          <FormControl>
            <FormLabel>Index Name</FormLabel>
            <Input
              name="vectorDbConfig.indexName"
              value={formData.vectorDbConfig.indexName}
              onChange={handleChange}
            />
          </FormControl>
        );
      case 'pgVector':
        return (
          <>
            <FormControl>
              <FormLabel>Host</FormLabel>
              <Input
                name="vectorDbConfig.host"
                value={formData.vectorDbConfig.host}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Port</FormLabel>
              <Input
                name="vectorDbConfig.port"
                value={formData.vectorDbConfig.port}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                name="vectorDbConfig.username"
                value={formData.vectorDbConfig.username}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                name="vectorDbConfig.password"
                type="password"
                value={formData.vectorDbConfig.password}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Database</FormLabel>
              <Input
                name="vectorDbConfig.database"
                value={formData.vectorDbConfig.database}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Table Name</FormLabel>
              <Input
                name="vectorDbConfig.tableName"
                value={formData.vectorDbConfig.tableName}
                onChange={handleChange}
              />
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{useCase ? 'Edit Use Case' : 'Create New Use Case'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Model</FormLabel>
              <Select
                name="model"
                value={formData.model}
                onChange={handleChange}
              >
                <option value="GPT-3.5">GPT-3.5</option>
                <option value="GPT-4">GPT-4</option>
                <option value="Claude">Claude</option>
                <option value="Cohere">Cohere</option>
                <option value="Gemini">Gemini</option>
                <option value="PaLM">PaLM</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Model API Key</FormLabel>
              <Input
                name="modelApiKey"
                value={formData.modelApiKey}
                onChange={handleChange}
              />
            </FormControl>
            <Button onClick={testLLMConnectionHandler} colorScheme="blue">Test LLM Connection</Button>
            {renderEmbeddingApiKeyField()}
            <FormControl>
              <FormLabel>Prompt Template</FormLabel>
              <Input
                name="prompt_template"
                value={formData.prompt_template}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Input
                name="category"
                value={formData.category}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Tags (comma-separated)</FormLabel>
              <Input
                name="tags"
                value={formData.tags}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
                <option value="archived">Archived</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Version</FormLabel>
              <Input
                name="version"
                value={formData.version}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Icon</FormLabel>
              <Input
                name="icon"
                value={formData.icon}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>External Access</FormLabel>
              <Switch
                name="externalAccess"
                isChecked={formData.externalAccess}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Allowed Domains (comma-separated)</FormLabel>
              <Input
                name="allowedDomains"
                value={formData.allowedDomains}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Allow File Upload</FormLabel>
              <Switch
                name="allowFileUpload"
                isChecked={formData.allowFileUpload}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Use Vector DB</FormLabel>
              <Switch
                name="useVectorDB"
                isChecked={formData.useVectorDB}
                onChange={handleChange}
              />
            </FormControl>
            {formData.useVectorDB && (
              <>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <Select
                    name="vectorDbConfig.type"
                    value={formData.vectorDbConfig.type}
                    onChange={handleChange}
                  >
                    <option value="Pinecone">Pinecone</option>
                    <option value="Milvus">Milvus</option>
                    <option value="FAISS">FAISS</option>
                    <option value="pgVector">pgVector</option>
                  </Select>
                </FormControl>
                {renderVectorDBFields()}
                {formData.vectorDbConfig.type && (
                  <Button onClick={testVectorDBConnectionHandler} colorScheme="blue">Test Vector DB Connection</Button>
                )}
              </>
            )}
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="useLLMEmbedding" mb="0">
                Use LLM Embedding
              </FormLabel>
              <Switch
                id="useLLMEmbedding"
                isChecked={useLLMEmbedding}
                onChange={(e) => setUseLLMEmbedding(e.target.checked)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Restrict to Enterprise Data</FormLabel>
              <Switch
                name="restrictToEnterpriseData"
                isChecked={formData.restrictToEnterpriseData}
                onChange={handleChange}
              />
              {formData.restrictToEnterpriseData ? (
                <Text>LLM will only use data from uploaded enterprise documents.</Text>
              ) : (
                <Text>LLM will use all available data, including its general knowledge.</Text>
              )}
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            {useCase ? 'Update' : 'Create'}
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UseCaseModal;