import React from 'react';
import { Button, ButtonGroup, Flex } from "@chakra-ui/react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <Flex justifyContent="center" mt={5}>
      <ButtonGroup variant="outline" spacing="2">
        {pages.map((page) => (
          <Button
            key={page}
            onClick={() => onPageChange(page)}
            colorScheme={currentPage === page ? "blue" : "gray"}
          >
            {page}
          </Button>
        ))}
      </ButtonGroup>
    </Flex>
  );
};

export default Pagination;
