import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, FormControl, FormLabel, Switch, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchContentModerationSettings, updateContentModerationSettings } from '../store/contentModerationSlice';

const ContentModerationSettings = () => {
  const dispatch = useDispatch();
  const { settings, isLoading } = useSelector((state) => state.contentModeration);
  const [localSettings, setLocalSettings] = useState(settings);

  useEffect(() => {
    dispatch(fetchContentModerationSettings());
  }, [dispatch]);

  useEffect(() => {
    setLocalSettings(settings);
  }, [settings]);

  const handleChange = (name, value) => {
    setLocalSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(updateContentModerationSettings(localSettings));
  };

  if (isLoading) {
    return <Text>Loading settings...</Text>;
  }

  return (
    <Box p={5}>
      <Heading mb={5}>Content Moderation and Safety Settings</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="enableContentFiltering" mb="0">
            Enable Content Filtering
          </FormLabel>
          <Switch
            id="enableContentFiltering"
            isChecked={localSettings.enableContentFiltering}
            onChange={(e) => handleChange('enableContentFiltering', e.target.checked)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Profanity Filter Strength</FormLabel>
          <Slider
            value={localSettings.profanityFilterStrength}
            onChange={(value) => handleChange('profanityFilterStrength', value)}
            min={0}
            max={100}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text mt={2}>Strength: {localSettings.profanityFilterStrength}%</Text>
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="blockExplicitContent" mb="0">
            Block Explicit Content
          </FormLabel>
          <Switch
            id="blockExplicitContent"
            isChecked={localSettings.blockExplicitContent}
            onChange={(e) => handleChange('blockExplicitContent', e.target.checked)}
          />
        </FormControl>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="enablePersonalInfoRedaction" mb="0">
            Enable Personal Information Redaction
          </FormLabel>
          <Switch
            id="enablePersonalInfoRedaction"
            isChecked={localSettings.enablePersonalInfoRedaction}
            onChange={(e) => handleChange('enablePersonalInfoRedaction', e.target.checked)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleSubmit}>
          Save Settings
        </Button>
      </VStack>
    </Box>
  );
};

export default ContentModerationSettings;