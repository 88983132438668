import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import api from '../utils/api';

const ResetPassword = () => {
  console.log('ResetPassword component rendered');

  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    console.log('ResetPassword: Component mounted');
    return () => {
      console.log('ResetPassword: Component unmounted');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('ResetPassword: Attempting to reset password for:', username);
      const response = await api.post('/auth/reset-password', { username, newPassword });
      console.log('Password reset response:', response.data);
      toast({
        title: "Password reset successful",
        description: "You can now login with your new password",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/login');
    } catch (error) {
      console.error('Password reset error:', error.response?.data || error.message);
      setError(error.response?.data?.message || 'An unexpected error occurred');
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <Heading as="h1" size="xl" textAlign="center">
            Reset Password
          </Heading>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
            />
          </FormControl>
          <Button type="submit" colorScheme="blue">
            Reset Password
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ResetPassword;