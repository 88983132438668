import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

export const fetchAdvancedAnalytics = createAsyncThunk(
  'analytics/fetchAdvancedAnalytics',
  async ({ metric, timeRange }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/analytics/${metric}`, { params: { timeRange } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch analytics');
    }
  }
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    usageMetrics: [],
    costMetrics: [],
    performanceComparisons: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvancedAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAdvancedAnalytics.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.metric === 'usage') {
          state.usageMetrics = action.payload.data;
        } else if (action.payload.metric === 'cost') {
          state.costMetrics = action.payload.data;
        } else if (action.payload.metric === 'performance') {
          state.performanceComparisons = action.payload.data;
        }
      })
      .addCase(fetchAdvancedAnalytics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default analyticsSlice.reducer;