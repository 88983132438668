import React, { useState, useEffect, useRef } from 'react';
import { Box, Input, Button, VStack, Text, Spinner, Select, Flex, useColorModeValue, Icon, Avatar, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { executeNaturalLanguageQuery } from '../store/querySlice';
import { fetchUseCases, uploadFile } from '../store/useCaseSlice';
import TypewriterEffect from './TypewriterEffect';
import { FaRobot, FaUser, FaUpload } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';

const DocumentChatInterface = () => {
  const [sessionId] = useState(() => Math.random().toString(36).substr(2, 9));
  const [query, setQuery] = useState('');
  const [selectedUseCase, setSelectedUseCase] = useState('');
  const [conversationHistory, setConversationHistory] = useState([]);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const { result, isLoading, error } = useSelector((state) => state.query);
  const { useCases } = useSelector((state) => state.useCase);
  const chatEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const cardBgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputBgColor = useColorModeValue("white", "gray.800");
  const placeholderColor = useColorModeValue("gray.400", "gray.500");

  useEffect(() => {
    dispatch(fetchUseCases({}));
  }, [dispatch]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversationHistory]);

  useEffect(() => {
    if (result) {
      setConversationHistory(prev => [
        ...prev,
        { role: 'assistant', content: result }
      ]);
    }
  }, [result]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUseCase) {
      toast({
        title: "No use case selected",
        description: "Please select a use case before sending a query.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newHistory = [
      ...conversationHistory,
      { role: 'user', content: query }
    ];
    setConversationHistory(newHistory);
    try {
      await dispatch(executeNaturalLanguageQuery({
        query,
        useCaseId: selectedUseCase,
        sessionId,
        history: newHistory
      })).unwrap();
    } catch (error) {
      setConversationHistory(prev => [
        ...prev,
        { role: 'error', content: `Error: ${error.message || 'An unknown error occurred'}` }
      ]);
    }
    setQuery('');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast({
        title: "No file selected",
        description: "Please select a file to upload.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!selectedUseCase) {
      toast({
        title: "No use case selected",
        description: "Please select a use case before uploading a file.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      await dispatch(uploadFile({ useCaseId: selectedUseCase, formData })).unwrap();
      toast({
        title: "File uploaded successfully",
        description: "You can now chat about the contents of the uploaded document.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      toast({
        title: "Upload failed",
        description: error.message || "An error occurred while uploading the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" h="80vh" bg={bgColor} borderRadius="xl" overflow="hidden" boxShadow="xl">
      <Box p={4} bg={cardBgColor} borderBottom="1px" borderColor={borderColor}>
        <Flex alignItems="center" justifyContent="space-between">
          <Select
            value={selectedUseCase}
            onChange={(e) => setSelectedUseCase(e.target.value)}
            placeholder="Select a use case"
            bg={inputBgColor}
            borderColor={borderColor}
            _hover={{ borderColor: "blue.300" }}
            _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
            maxWidth="60%"
          >
            {useCases.map((useCase) => (
              <option key={useCase.uniqueIdentifier} value={useCase.uniqueIdentifier}>
                {useCase.name}
              </option>
            ))}
          </Select>
          <Flex alignItems="center">
            <Input
              type="file"
              onChange={handleFileChange}
              hidden
              ref={fileInputRef}
              accept=".txt,.pdf,.doc,.docx"
            />
            <Button
              onClick={() => fileInputRef.current.click()}
              leftIcon={<Icon as={FaUpload} />}
	      colorScheme="blue"
              mr={2}
            >
              Select File
            </Button>
            <Button
              onClick={handleFileUpload}
              colorScheme="green"
              isDisabled={!file}
            >
              Upload
            </Button>
          </Flex>
        </Flex>
        {file && (
          <Text mt={2} fontSize="sm">
            Selected file: {file.name}
          </Text>
        )}
      </Box>
      <Box flex="1" overflowY="auto" p={4} css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: borderColor,
          borderRadius: '24px',
        },
      }}>
        {conversationHistory.map((message, index) => (
          <Flex 
            key={index} 
            mb={4} 
            alignItems="start"
            justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
          >
            {message.role !== 'user' && (
              <Avatar icon={<Icon as={FaRobot} />} bg="blue.500" color="white" size="sm" mr={2} />
            )}
            <Box
              maxWidth="70%"
              bg={message.role === 'user' ? 'blue.100' : cardBgColor}
              color={message.role === 'user' ? 'gray.800' : 'inherit'}
              p={3}
              borderRadius="lg"
              boxShadow="md"
            >
              {message.role === 'assistant' ? (
                <TypewriterEffect text={message.content} />
              ) : (
                <Text>{message.content}</Text>
              )}
            </Box>
            {message.role === 'user' && (
              <Avatar icon={<Icon as={FaUser} />} bg="green.500" color="white" size="sm" ml={2} />
            )}
          </Flex>
        ))}
        {isLoading && <Spinner />}
        {error && <Text color="red.500">{typeof error === 'string' ? error : error.message}</Text>}
        <div ref={chatEndRef} />
      </Box>
      <Box p={4} bg={cardBgColor} borderTop="1px" borderColor={borderColor}>
        <form onSubmit={handleSubmit}>
          <Flex>
            <Input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Ask about the uploaded document..."
              bg={inputBgColor}
              borderColor={borderColor}
              _hover={{ borderColor: "blue.300" }}
              _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
              _placeholder={{ color: placeholderColor }}
              mr={2}
            />
            <Button 
              type="submit" 
              colorScheme="blue" 
              isLoading={isLoading} 
              leftIcon={<Icon as={IoMdSend} />}
            >
              Send
            </Button>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
};

export default DocumentChatInterface;
