import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from 'react-redux';
import { uploadFile } from '../store/useCaseSlice';
import io from 'socket.io-client';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const FileUploadModal = ({ isOpen, onClose, useCase }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast();

  const initSocket = useCallback(() => {
    const newSocket = io(BACKEND_URL, {
      transports: ['websocket'],
      reconnectionAttempts: 3,
      timeout: 10000
    });
    
    newSocket.on('connect', () => {
      console.log('Socket connected');
    });
    
    newSocket.on('uploadProgress', (data) => {
      setUploadProgress(data.progress);
    });
    
    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      toast({
        title: "Connection Error",
        description: "Unable to establish real-time connection. Progress updates may not be available.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    });
    
    setSocket(newSocket);
    return newSocket;
  }, [toast]);

  useEffect(() => {
    let socketInstance = null;
    if (isOpen) {
      socketInstance = initSocket();
    }
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [isOpen, initSocket]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast({
        title: "Error",
        description: "Please select a file to upload",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);
      
      const config = socket && socket.connected ? { headers: { 'X-Socket-ID': socket.id } } : {};
      
      await dispatch(uploadFile({ 
        useCaseId: useCase.uniqueIdentifier, 
        formData,
        config
      })).unwrap();

      toast({
        title: "File Uploaded",
        description: "File has been successfully uploaded and processed",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFile(null);
      setUploadProgress(0);
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An error occurred while uploading the file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload File for {useCase?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Select File</FormLabel>
              <Input type="file" onChange={handleFileChange} />
            </FormControl>
            {uploadProgress > 0 && (
              <VStack w="100%">
                <Progress value={uploadProgress} w="100%" />
                <Text>{uploadProgress}% Complete</Text>
              </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit} isDisabled={uploadProgress > 0 && uploadProgress < 100}>
            Upload
          </Button>
          <Button variant="ghost" onClick={onClose} isDisabled={uploadProgress > 0 && uploadProgress < 100}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileUploadModal;