import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Heading, SimpleGrid, Text, VStack, HStack, Select, Alert, AlertIcon, Spinner, useColorModeValue
} from "@chakra-ui/react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdvancedAnalytics } from '../store/analyticsSlice';

const AdvancedAnalyticsDashboard = () => {
  const dispatch = useDispatch();
  const { usageMetrics, costMetrics, performanceMetrics, isLoading, error } = useSelector((state) => state.analytics);
  const [selectedMetric, setSelectedMetric] = useState('usage');
  const [selectedTimeRange, setSelectedTimeRange] = useState('7d');

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  useEffect(() => {
    dispatch(fetchAdvancedAnalytics({ metric: selectedMetric, timeRange: selectedTimeRange }));
  }, [dispatch, selectedMetric, selectedTimeRange]);

  const renderUsageChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={usageMetrics}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" stroke={textColor} />
        <YAxis stroke={textColor} />
        <Tooltip contentStyle={{ backgroundColor: bgColor, color: textColor, borderColor: borderColor }} />
        <Line type="monotone" dataKey="apiCalls" stroke="#8884d8" name="API Calls" />
        <Line type="monotone" dataKey="tokensUsed" stroke="#82ca9d" name="Tokens Used" />
      </LineChart>
    </ResponsiveContainer>
  );

	// In the renderCostChart function
	const renderCostChart = () => (
	  <ResponsiveContainer width="100%" height={300}>
		{costMetrics && costMetrics.length > 0 ? (
		  <BarChart data={costMetrics}>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="model" stroke={textColor} />
			<YAxis stroke={textColor} />
			<Tooltip contentStyle={{ backgroundColor: bgColor, color: textColor, borderColor: borderColor }} />
			<Bar dataKey="cost" fill="#8884d8" name="Cost" />
		  </BarChart>
		) : (
		  <Text>No cost data available</Text>
		)}
	  </ResponsiveContainer>
	);

  const renderPerformanceChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={performanceMetrics}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="model" stroke={textColor} />
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
        <Tooltip contentStyle={{ backgroundColor: bgColor, color: textColor, borderColor: borderColor }} />
        <Bar yAxisId="left" dataKey="latency" fill="#8884d8" name="Latency (ms)" />
        <Bar yAxisId="right" dataKey="accuracy" fill="#82ca9d" name="Accuracy (%)" />
      </BarChart>
    </ResponsiveContainer>
  );

  const insights = useMemo(() => {
    if (selectedMetric === 'usage' && usageMetrics.length > 1) {
      const lastDay = usageMetrics[usageMetrics.length - 1];
      const prevDay = usageMetrics[usageMetrics.length - 2];
      const apiCallChange = ((lastDay.apiCalls - prevDay.apiCalls) / prevDay.apiCalls) * 100;
      const tokenChange = ((lastDay.tokensUsed - prevDay.tokensUsed) / prevDay.tokensUsed) * 100;
      return {
        main: `API calls ${apiCallChange > 0 ? 'increased' : 'decreased'} by ${Math.abs(apiCallChange).toFixed(2)}% in the last day.`,
        secondary: `Token usage ${tokenChange > 0 ? 'increased' : 'decreased'} by ${Math.abs(tokenChange).toFixed(2)}%.`
      };
    } else if (selectedMetric === 'cost' && costMetrics.length > 0) {
      const totalCost = costMetrics.reduce((sum, item) => sum + item.cost, 0);
      const highestCostModel = costMetrics.reduce((prev, current) => (prev.cost > current.cost) ? prev : current);
      return {
        main: `Total cost for the period: $${totalCost.toFixed(2)}.`,
        secondary: `${highestCostModel.model} accounts for ${((highestCostModel.cost / totalCost) * 100).toFixed(2)}% of the total cost.`
      };
    } else if (selectedMetric === 'performance' && performanceMetrics.length > 0) {
      const bestAccuracy = performanceMetrics.reduce((prev, current) => (prev.accuracy > current.accuracy) ? prev : current);
      const lowestLatency = performanceMetrics.reduce((prev, current) => (prev.latency < current.latency) ? prev : current);
      return {
        main: `${bestAccuracy.model} has the highest accuracy at ${bestAccuracy.accuracy.toFixed(2)}%.`,
        secondary: `${lowestLatency.model} has the lowest latency at ${lowestLatency.latency.toFixed(2)}ms.`
      };
    }
    return { main: "Select a metric and time range to see insights.", secondary: "" };
  }, [selectedMetric, usageMetrics, costMetrics, performanceMetrics]);

  const recommendations = useMemo(() => {
    if (selectedMetric === 'usage') {
      return "Consider implementing caching for frequent queries to reduce API calls and token usage.";
    } else if (selectedMetric === 'cost') {
      return "Evaluate using less expensive models for simpler tasks to optimize costs.";
    } else if (selectedMetric === 'performance') {
      return "Balance model selection based on task complexity, required accuracy, and acceptable latency.";
    }
    return "";
  }, [selectedMetric]);

  const renderChart = () => {
    switch (selectedMetric) {
      case 'usage':
        return renderUsageChart();
      case 'cost':
        return renderCostChart();
      case 'performance':
        return renderPerformanceChart();
      default:
        return null;
    }
  };

  return (
    <Box p={5} bg={bgColor} borderRadius="lg" boxShadow="sm">
      <Heading mb={5} color={textColor}>Advanced Analytics Dashboard</Heading>
      <HStack spacing={4} mb={5}>
        <Select value={selectedMetric} onChange={(e) => setSelectedMetric(e.target.value)} bg={bgColor} color={textColor}>
          <option value="usage">Usage Metrics</option>
          <option value="cost">Cost Metrics</option>
          <option value="performance">Performance Metrics</option>
        </Select>
        <Select value={selectedTimeRange} onChange={(e) => setSelectedTimeRange(e.target.value)} bg={bgColor} color={textColor}>
          <option value="7d">Last 7 days</option>
          <option value="30d">Last 30 days</option>
          <option value="90d">Last 90 days</option>
        </Select>
      </HStack>

      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}

      {isLoading ? (
        <Box textAlign="center" py={10}>
          <Spinner size="xl" />
          <Text mt={4} color={textColor}>Loading analytics data...</Text>
        </Box>
      ) : (
        <Box>
          {renderChart()}
          <SimpleGrid columns={1} spacing={10} mt={10}>
            <VStack align="stretch">
              <Heading size="md" mb={3} color={textColor}>Insights</Heading>
              <Text color={textColor}>{insights.main}</Text>
              <Text color={textColor}>{insights.secondary}</Text>
            </VStack>
            <VStack align="stretch">
              <Heading size="md" mb={3} color={textColor}>Recommendations</Heading>
              <Text color={textColor}>{recommendations}</Text>
            </VStack>
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
};

export default AdvancedAnalyticsDashboard;