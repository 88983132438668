import React, { useState } from 'react';
import { Box, VStack, Heading, Text, Button, HStack, Input, Textarea, Alert, AlertIcon, Badge } from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ChevronRightIcon, ViewIcon, ViewOffIcon, AttachmentIcon } from '@chakra-ui/icons';

const UseCaseCard = ({ 
  useCase, 
  onEdit, 
  onDelete, 
  onExecute, 
  isExecuting, 
  executionResult, 
  executionError, 
  isSelected, 
  onSelect,
  onUpload,
  bgColor,
  textColor,
  borderColor
}) => {
  const [input, setInput] = useState('');
  const [userContext, setUserContext] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);

  const handleExecute = () => {
    onExecute(useCase.uniqueIdentifier, input, userContext);
  };

  const maskApiKey = (apiKey) => {
    if (!apiKey) return 'N/A';
    return `${apiKey.substring(0, 4)}...${apiKey.substring(apiKey.length - 4)}`;
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const renderExecutionResult = () => {
    if (!executionResult) return null;

    return (
      <Box mt={4}>
        <Heading size="sm">Execution Result:</Heading>
        <Text>{executionResult.result?.content || 'No content available'}</Text>
        <Text fontSize="sm" color="gray.500">
          Context: {executionResult.result?.metadata?.context || 'No context available'}
        </Text>
      </Box>
    );
  };

  const renderExecutionError = () => {
    if (!executionError) return null;

    return (
      <Alert status="error" mt={4}>
        <AlertIcon />
        <Text>{typeof executionError === 'string' ? executionError : executionError.message || 'An unknown error occurred'}</Text>
      </Alert>
    );
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg={bgColor} borderColor={borderColor}>
      <VStack align="start" spacing={3}>
        <Heading size="md" color={textColor}>{useCase.name || 'Unnamed Use Case'}</Heading>
        <Text fontSize="sm" color={textColor}>{useCase.description || 'No description available'}</Text>
        <HStack>
          {useCase.tags && useCase.tags.map((tag, index) => (
            <Badge key={index} colorScheme="blue">{tag}</Badge>
          ))}
        </HStack>
        <Text fontSize="sm" color={textColor}>Model: <strong>{useCase.model || 'N/A'}</strong></Text>
        <Text fontSize="sm" color={textColor}>Category: <strong>{useCase.category || 'N/A'}</strong></Text>
        <Text fontSize="sm" color={textColor}>ID: <strong>{useCase.uniqueIdentifier || 'N/A'}</strong></Text>
        <HStack>
          <Text fontSize="sm" color={textColor}>API Key: </Text>
          <Text fontSize="sm" fontWeight="bold" color={textColor}>
            {showApiKey ? useCase.apiKey : maskApiKey(useCase.apiKey)}
          </Text>
          <Button size="xs" onClick={toggleApiKeyVisibility}>
            {showApiKey ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        </HStack>
        
        <VStack spacing={2} align="stretch" width="100%">
          <Input
            placeholder="Enter input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <Textarea
            placeholder="Enter context (optional)"
            value={userContext}
            onChange={(e) => setUserContext(e.target.value)}
          />
          <Button
            leftIcon={<ChevronRightIcon />}
            colorScheme="green"
            onClick={handleExecute}
            isLoading={isExecuting}
          >
            Execute
          </Button>
        </VStack>

        {renderExecutionResult()}
        {renderExecutionError()}

        <HStack spacing={2} mt={2}>
          <Button size="sm" leftIcon={<EditIcon />} colorScheme="blue" variant="outline" onClick={() => onEdit(useCase)}>
            Edit
          </Button>
          <Button size="sm" leftIcon={<DeleteIcon />} colorScheme="red" variant="outline" onClick={() => onDelete(useCase.uniqueIdentifier)}>
            Delete
          </Button>
          {useCase.useVectorDB && (
            <Button size="sm" leftIcon={<AttachmentIcon />} colorScheme="purple" variant="outline" onClick={() => onUpload(useCase)}>
              Upload
            </Button>
          )}
        </HStack>
      </VStack>
    </Box>
  );
};

export default UseCaseCard;