import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Input,
  Button,
  Box,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Text,
  Link,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { login } from '../store/authSlice';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
//      console.log('Attempting login with:', { username, password: password.substring(0, 3) + '...' });
      const resultAction = await dispatch(login({ username, password }));
      if (login.fulfilled.match(resultAction)) {
        navigate('/dashboard');
      } else if (login.rejected.match(resultAction)) {
        setError(resultAction.payload?.message || 'Login failed');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" mt={8}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <Heading as="h1" size="xl" textAlign="center">
            Login
          </Heading>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </FormControl>
          <Button type="submit" colorScheme="blue">
            Login
          </Button>
          <Text textAlign="center">
            Forgot your password?{' '}
            <Link as={RouterLink} to="/reset-password" color="blue.500">
              Reset Password
            </Link>
          </Text>
        </VStack>
      </form>
    </Box>
  );
};

export default Login;